import axios from 'axios';

export const DownloadTypes = {
  OBJECT_PROPERTIES_HISTORY: 'OBJECT_PROPERTIES_HISTORY'
}

const useMedia = () => {
  const token = localStorage.getItem('authToken');
  const url = window.__pixelConfig.APP_MEDIA_SERVER;

  const getImageById = (id, type = 'download') => {
    return `${url}/${type}/${id}/${token}`;
  };

  const uploadMedia = (obj = { file: null, progress: 0, id: 0 }) => {
    const formData = new FormData();
    formData.append('uploaded_file', obj.file);

    return axios.request({
      method: 'POST',
      url: `${url}/upload/${obj.id}/${token}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: function(progressEvent) {
        obj.progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      },
      data: formData
    });
  };

  const computeIcon = item => {
    if (item.mIcon) {
      return getImageById(item.mIcon);
    }

    if (item.mPicture) {
      return getImageById(item.mPicture);
    }

    return null;
  };

  const getHistoryProperties = data => {
    return axios.post(`${url}/propertyhistory/${token}`, data, {
      responseType: 'blob'
    });
  };

  const getObjectPropertiesHistory = data => {
    return axios.post(`${url}/objectpropertieshistory/${token}`, data, {
      responseType: 'blob'
    });
  };

  const getNotificationsHistory = data => {
    return axios.post(`${url}/notificationshistory/${token}`, data, {
      responseType: 'blob'
    });
  };

  const getControlsHistory = data => {
    return axios.post(`${url}/controlshistory/${token}`, data, {
      responseType: 'blob'
    });
  };

  const getObjectHistory = data => {
    return axios.post(`${url}/monitoring-history/${token}`, data, {
      responseType: 'blob'
    });
  };

  return {
    getImageById,
    computeIcon,
    uploadMedia,
    getHistoryProperties,
    getNotificationsHistory,
    getObjectPropertiesHistory,
    getControlsHistory,
    getObjectHistory
  };
};
export default useMedia;
